import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  title: '<b>Better MAtCH</b> Architecture',
  adaptabilityItem: '<span><strong>Maximum adaptability – </strong>Adapt your systems quickly and easily to new requirements.</span>',
  innovationItem: '<span><strong>Increased speed of innovation – </strong>Independent further development of individual modules.</span>',
  integrationItem: '<span><strong>Improved integration – </strong>Seamless connection between your systems and applications.</span>',
  scalabilityItem: '<span><strong>Scalable and cost-effective – </strong>Efficient cloud solutions that adapt to your needs.</span>',
  longevityItem: '<span><strong>Long-lasting solutions – </strong>Future-proof architecture for sustainable success.</span>'
})
