// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { StaticImage } from 'gatsby-plugin-image'
import Section from '../../../components/CommonLanding/Section'
import messages from './MarketingContainer.lang'
import baseStyles from './MarketingContainer.scss'

type Props = {
  ...StyleProps
}

const matchCompanyImg = (
  <StaticImage src="../../../images/xmas/mach-company.png" alt="" objectFit="contain" className="image" />
)

const MarketingContainer = ({ styles }: Props) => {

  return (
    <Section
      title={messages.title}
      text={messages.descriptionFull}
      gallery={[
        {
          clickable: false,
          img: matchCompanyImg
        }
      ]}
      modeSettings={{
        mode: 'dark',
        bgColour: 'black'
      }}
      styles={styles.root}
    />
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(MarketingContainer)
