// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { StaticImage } from 'gatsby-plugin-image'
import messages from './VideoContainer.lang'
import baseStyles from './VideoContainer.scss'
import VideoTeaser from '../../ProductPage/VideoTeaser'

type Props = {
  ...StyleProps
}

const thumbnail = (
  <StaticImage
    src="../../../images/xmas/xmas_snow_background.png"
    alt=""
    objectFit="cover"
    className="imageCoverTop"
  />
)

const VideoContainer = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <VideoTeaser
        videoUrl="https://www.youtube.com/embed/i4iWaMQbB80"
        imageDesktop={thumbnail}
        styles={styles.videoTeaser}
        title={<span dangerouslySetInnerHTML={{ __html: formatMessage(messages.title) }} />}
      />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(VideoContainer)
