// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import CaseHeroTeaser from '../../CaseStudy/CaseHeroTeaser'
import messages from './XmasTeaser.lang';
import baseStyles from './XmasTeaser.scss';

const heroImage = (
  <StaticImage
    src="../../../images/xmas/xmas_2024_background.png"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
);

const rightImage = (
  <StaticImage
    src="../../../images/xmas/mach-santa-illu.png"
    alt=""
    objectFit="contain"
    className="image"
  />
);

type Props = {
  ...StyleProps,
  preHeadlines?: any,
  responsiveHeight?: boolean,
  straight?: boolean,
};

const HeroTeaser = ({ styles, preHeadlines, responsiveHeight, straight }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.root}>
      <CaseHeroTeaser
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description)}
        imageDesktop={heroImage}
        caseImg={rightImage}
        styles={styles.heroTeaser}
        ancor
        titleLink='architecture'
        titleLinkIcon="icon-arrow_03-down-medium"
      />
    </div>
  );
};

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(HeroTeaser);
