// @flow

import React from 'react'
import { useIntl, Link } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import ScrollAnimation from 'react-animate-on-scroll'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container, Row, Col } from '../../../components/basic/Grid'
import Button from '../../../components/basic/Button'
import CaseList from '../CaseList'
import baseStyles from './CaseTeaser.scss'
import messages from '../CaseStudy.lang'

type Props = {
  ...StyleProps,
  title: string,
  preTitle?: string,
  text?: string,
  richText?: string,
  reversed?: boolean,
  imgWide?: boolean,
  imgStatic?: boolean,
  list?: Array<string | Object>,
  img: Object,
  link?: string,
  linkTitle?: string,
  children?: Object
}

const CaseTeaser = ({
  styles,
  preTitle,
  children,
  title,
  text,
  richText,
  list,
  img,
  reversed,
  imgWide,
  imgStatic,
  link,
  linkTitle
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div
      className={classNames(
        styles.root,
        reversed && styles['reversed'],
        imgStatic && styles['imgStatic'],
        imgWide && styles['imgWide']
      )}
    >
      <Container className={styles.container} fluid>
        <Row className={styles.row}>
          <Col xs="12" md="6" className={classNames(styles.col, styles.colImg)}>
            <div className={styles.imageWrap}>
              <ScrollAnimation animateIn={reversed ? 'fadeInRight' : 'fadeInLeft'} animateOnce>
                <div className={styles.imageContainer}>{img}</div>
              </ScrollAnimation>
            </div>
          </Col>
          <Col xs="12" md="6" className={classNames(styles.col, styles.colContent)}>
            <ScrollAnimation animateIn={reversed ? 'fadeInLeft' : 'fadeInRight'} animateOnce>
              <div className={styles.content}>
                <CaseList
                  preTitle={preTitle}
                  title={title}
                  text={text}
                  richText={richText}
                  list={list}
                  styles={styles.list}
                />
                {link && (
                  <div className={styles.actionWrapper}>
                    <Link to={link} className={styles.btnLink}>
                      <Button styles={styles.btn} tag="span" color="accent" iconRight="icon-arrow_03-right-medium">
                        {linkTitle || formatMessage(messages.btnCheck)}
                      </Button>
                    </Link>
                  </div>
                )}
                {children}
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseTeaser)
