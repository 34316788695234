// @flow
import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'
import { Container } from '../../components/basic/Grid'
import XmasTeaser from './XmasTeaser'
import Architecture from './Architecture'
import VideoContainer from './VideoContainer'
import MarketingContainer from './MarketingContainer'
import ContactTeaser from '../ContactTeaser'
import messages from './XmasPage.lang'
import baseStyles from './XmasPage.scss'

type Props = {
  ...StyleProps
}

const XmasPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <XmasTeaser />
      <Architecture />
      <VideoContainer />
      <MarketingContainer />
      <Container>
        <ContactTeaser
          styles={styles.contactTeaser}
          title={<FormattedMessageSanitized tagName="span" {...messages.contactTeaserTitle} />}
          introInfo={<FormattedMessageSanitized tagName="span" {...messages.contactTeaserIntroInfo} />}
          hideSecondaryTitle
          btnLabel={formatMessage(messages.contactbtnLabel)}
        />
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(XmasPage)
