import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import SEO from '../../components/seo'
import Layout from '../../components/Layout/index'
import Header from '../../modules/Header'
import Footer from '../../modules/Footer'
import XmasPage from '../../components/XmasPage'
import messages from '../../messages/pageTitles.lang'
import metaDescription from '../../messages/pageMetaDescriptions.lang'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleXmasPage)}
        description={formatMessage(metaDescription.xmasDescription)}
        lang={locale}
      />
      <XmasPage />
    </Layout>
  )
}

export default IndexPage
