import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  title: 'It’s a Christmas <b>MA</b>t<b>CH</b>: <br/> <b> Collaborative solutions </b> <br/> for a worry-free new year',
  description: '<p>Discover how MACH Architecture and freshcells can make your digital projects shine.</p>',
  btnText: 'Learn more',
  contactTeaserTitle: '<b>Get free consultation</b> on how to modernize your systems',
  contactTeaserIntroInfo: 'Do you have any questions or like to talk about how to tackle the digital challenges of your business now? Do not hesitate contacting us. We look forward to your questions and would love to support you.{br}{br}',
  contactbtnLabel: 'Get free consultation now'
})
