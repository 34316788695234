// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import baseStyles from './CaseList.scss'

type Props = {
  ...StyleProps,
  title?: string,
  text?: string,
  richText?: string,
  preTitle?: string,
  list?: Array<string>
}

const CaseList = ({ styles, preTitle, title, text, richText, list }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      {preTitle && <div className={styles.preTitle}>{formatMessage(preTitle)}</div>}
      {title && <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: formatMessage(title) }} />}
      {text && <p className={styles.text}>{formatMessage(text)}</p>}
      {richText && <div className={styles.text} dangerouslySetInnerHTML={{ __html: formatMessage(richText) }} />}
      {list && (
        <ul className={styles.list}>
          {list.map((item, i) => {
            return (
              <li
                className={styles.listItem}
                key={'caseList' + item + i}
                dangerouslySetInnerHTML={{ __html: formatMessage(item) }}
              />
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseList)
