// @flow

import React from 'react'
import CaseTeaser from '../../../components/CaseStudy/CaseTeaser'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import baseStyles from './Architecture.scss'
import messages from './Architecture.lang'


type Props = {
  ...StyleProps
}

const architectureImg = (
  <StaticImage src="../../../images/xmas/architecture_banner.png" alt="" objectFit="contain" className="image" />
)

const Architecture = ({ styles }: Props) => {
  const teaserList = [
    messages.adaptabilityItem,
    messages.innovationItem,
    messages.integrationItem,
    messages.scalabilityItem,
    messages.longevityItem
  ]
  return (
    <div id='architecture'>
      <CaseTeaser
        styles={styles.root}
        title={messages.title}
        img={architectureImg}
        list={teaserList}
      />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Architecture)
